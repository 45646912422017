(function () {
	'use strict';
	$(() => {
		/** @var {NodeListOf<HTMLDialogElement>} dialogs */
		const dialogs = document.querySelectorAll('dialog.event-popup');
		if (!dialogs.length) return;

		for (const dialog of dialogs) {
			dialog.addEventListener('click', (event) => {
				if (event.target === dialog) {
					dialog.close();
					document.documentElement.classList.remove('disable-scroll');
				}
			});
			dialog.addEventListener('close', () => {
				document.documentElement.classList.remove('disable-scroll');
			});

			/** @var {NodeListOf<HTMLButtonElement>} closeButtons */
			const closeButtons = dialog.querySelectorAll('button[data-action="close-event-popup"]');

			for (const button of closeButtons) {
				button.addEventListener('click', () => {
					dialog.close();
					document.documentElement.classList.remove('disable-scroll');
				});
			}
		}

		/** @var {NodeListOf<HTMLButtonElement>} triggerButtons */
		const triggerButtons = document.querySelectorAll('button[data-action="open-event-popup"]');
		for (const button of triggerButtons) {
			const popupId = button.dataset.popupId;

			/** @var {HTMLDialogElement} relatedDialog */
			const relatedDialog = [...dialogs].find((dialog) => dialog.id === popupId);

			button.addEventListener('click', () => {
				if (!relatedDialog) return;
				relatedDialog.showModal();
				document.documentElement.classList.add('disable-scroll');
			});
		}
	});
}(jQuery));
